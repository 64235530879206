<template>
  <div>
  {{content}}
  </div>
</template>
<script>
export default {
  name:'Item17Contact',
  props: {
    content: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

